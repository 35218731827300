import { Injectable } from '@angular/core';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { ELF_PREFIX } from 'src/app/app.constants';
import { TimerAction, TimestampEntity } from './timer.interface';
import { addMinutes, differenceInSeconds, format, isToday, max, min } from 'date-fns';
import { map } from 'rxjs';
import { calculateSessionTime } from 'src/app/utilities/timestamp.utils';
import { HistoryRepository } from 'src/app/analytics/state/history.repository';

@Injectable({
  providedIn: 'root',
})
export class TimestampRepository {
  timestamps$ = this.history.currentTimestamps$;

  multiplier$ = this.history.currentMultiplier$.pipe(
    map((multiplier) => {
      if (multiplier === undefined) {
        return 5;
      }
      return multiplier;
    }),
  );

  goal$ = this.history.currentGoal$;

  isPaused$ = this.history.currentTimestamps$.pipe(map(() => this.isPaused()));

  mode$ = this.history.currentTimestamps$.pipe(
    map((entities) => this.getLastModeWithoutPauses(entities)),
  );

  todaysFocusTime$ = this.history.currentTimestamps$.pipe(
    map(() => this.getTodaysTime(TimerAction.Focus)),
  );
  todaysRestTime$ = this.history.currentTimestamps$.pipe(
    map(() => this.getTodaysTime(TimerAction.Rest)),
  );

  todaysTotalTime$ = this.history.currentTimestamps$.pipe(
    map(() => {
      return this.getTodaysTime(TimerAction.Focus) + this.getTodaysTime(TimerAction.Rest);
    }),
  );

  avgFocus$ = this.todaysFocusTime$.pipe(
    map((focus) => {
      const focusSessions = this.history
        .getTodayTimestamps()
        .filter((entity) => entity.action === TimerAction.Focus);

      if (focusSessions.length === 0) {
        return 0;
      }

      let numFocusSession = focusSessions.length;

      if (this.getCurrentMode() === TimerAction.Focus) {
        numFocusSession -= 1;
        focus -= this.getCurrentFocusedSeconds();
      }

      if (numFocusSession === 0) {
        return 0;
      }

      return focus / numFocusSession;
    }),
  );

  lastEntry$ = this.history.currentTimestamps$.pipe(map((entities) => entities.slice(-1)[0]));

  constructor(private history: HistoryRepository) {}

  getMultiplier() {
    return this.history.getToday()?.multiplier || 5;
  }

  //Goals
  clearGoal() {
    this.history.addSettings({ goal: null }, format(new Date(), 'yyyy-MM-dd'));
  }

  getTodaysGoal() {
    const goal = this.history.getToday()?.goal || null;

    return { goal };
  }

  getLastGoal() {
    return this.history.getLastUnactiveEntry()?.goal;
  }

  getCurrentFocusedSeconds() {
    let entities = this.history.getTodayTimestamps().slice().reverse();

    if (entities.length === 0) {
      return 0;
    }

    if (this.getCurrentMode() !== TimerAction.Focus) {
      return 0;
    }

    const lastEntry = entities[0];

    if (lastEntry.action === TimerAction.Rest) {
      return 0;
    }

    // If timer is running now
    if (lastEntry.action === TimerAction.Focus) {
      return differenceInSeconds(new Date(), new Date(lastEntry.timestamp));
    }

    let totalFocusedSeconds = 0;

    if (lastEntry.action === TimerAction.Resume) {
      totalFocusedSeconds = differenceInSeconds(new Date(), new Date(lastEntry.timestamp));
    }

    // If there is a long cycle of pause/resumes -> Sum the total focused time
    for (let key in entities) {
      const entity = entities[key];
      const index = parseInt(key);
      const previousEntity = entities[index - 1];
      if (
        (entity.action === TimerAction.Focus || entity.action === TimerAction.Resume) &&
        previousEntity?.action === TimerAction.Pause
      ) {
        totalFocusedSeconds += differenceInSeconds(
          new Date(previousEntity.timestamp),
          new Date(entity.timestamp),
        );
      }

      // Ends cycle
      if (index > 0 && entity.action === TimerAction.Focus) {
        break;
      }

      if (entity.action === TimerAction.Rest) {
        console.error('Found a Rest where it should not be');
        break;
      }
    }

    return totalFocusedSeconds;
  }

  //scenarios:
  // 1. Focus -> Now
  //  total = now - focus start
  // 2. Focus -> Pause -> Resume -> Now
  //  total = now - focus start - pause time
  // 3. Focus -> Pause -> Resume -> .... -> Pause -> Resume -> Now
  //  total = now - focus start - pause time
  getLastRestStartTimestamp() {
    const entities = this.history.getTodayTimestamps().slice().reverse();

    if (entities.length === 0) {
      console.error('Failed to find any entities');
      return null;
    }

    const lastRest = entities.findIndex((entity) => entity.action === TimerAction.Rest);
    const lastFocus = entities.findIndex((entity) => entity.action === TimerAction.Focus);

    if (lastFocus != -1 && lastFocus < lastRest) {
      console.error('Found a Focus entry after a Rest entry');
      return null;
    }

    if (lastRest == -1) {
      console.error('No Rest entry found');
      return null;
    }

    if (lastRest == 0) {
      return entities[lastRest].timestamp;
    }

    // remember that array was reversed
    const latestAction = entities[0];
    if (latestAction.action === TimerAction.Resume) {
      return latestAction.timestamp;
    }

    return null;
  }

  isPaused() {
    const entities = this.history.getTodayTimestamps();
    if (entities.length > 0 && entities.slice(-1)[0].action === TimerAction.Pause) {
      return true;
    }

    return false;
  }

  getCurrentMode() {
    const entities = this.history.getTodayTimestamps();

    return this.getLastModeWithoutPauses(entities);
  }

  getTodaysTime(mode: TimerAction.Rest | TimerAction.Focus) {
    const entities = this.history.getTodayTimestamps();

    return calculateSessionTime(mode, entities);
  }

  getLastModeWithoutPauses(entities: TimestampEntity[]) {
    const entitiesWithoutPause = entities.filter(
      (entity) => entity.action !== TimerAction.Pause && entity.action !== TimerAction.Resume,
    );
    const lastEntity = entitiesWithoutPause.slice(-1)[0];

    if (lastEntity) {
      return lastEntity.action;
    }

    return TimerAction.Stop;
  }

  getMinTimeForEditing() {
    const entities = this.history.getTodayTimestamps();

    if (entities.length <= 1) {
      return null;
    }

    const secondLastEntry = entities[entities.length - 2];

    if (secondLastEntry.action !== TimerAction.Stop || entities.length <= 2) {
      const minTime = secondLastEntry ? addMinutes(new Date(secondLastEntry.timestamp), 1) : null;
      return minTime ? format(min([minTime, new Date()]), 'HH:mm') : null;
    }

    const thirdLastEntry = entities[entities.length - 3];

    // always add one minute more
    const minTime = thirdLastEntry ? addMinutes(new Date(thirdLastEntry.timestamp), 1) : null;

    return minTime ? format(min([minTime, new Date()]), 'HH:mm') : null;
  }

  getNumFocusSessions() {
    const entities = this.history.getTodayTimestamps();
    return entities.filter((entity) => entity.action === TimerAction.Focus).length;
  }

  getEntries() {
    return this.history.getTodayTimestamps();
  }
}
