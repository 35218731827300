import { Injectable } from '@angular/core';
import { AuthCredential, SignInResult, User } from '@capacitor-firebase/authentication';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { ELF_PREFIX } from 'src/app/app.constants';

interface AuthState {
  user: User | null;
  credential: AuthCredential | null;
}

const initialState: AuthState = {
  user: null,
  credential: null,
};

const name = ELF_PREFIX + 'auth';

const store = createStore({ name }, withProps<AuthState>(initialState));

export const persist = persistState(store, {
  key: name,
  storage: localStorageStrategy,
});

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  user$ = store.pipe(select((state) => state.user));

  setAuth(authResult: SignInResult) {
    store.update(setProps({ user: authResult.user, credential: authResult.credential }));
  }

  setUser(user: User | null) {
    store.update(setProps({ user }));
  }

  getUser() {
    return store.query((state) => state.user);
  }

  isAuthenticated() {
    return store.query((state) => state.user) !== null;
  }
}
