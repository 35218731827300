export function secondsToTimeInHours(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const prettyHour = hours > 0 ? `${hours}h` : '';
  const prettyMinutes = minutes > 0 ? `${minutes.toString().padStart(2, '0')}m` : '0m';

  return `${prettyHour} ${prettyMinutes}`;
}

export function secondsToTimeInHoursIncSec(seconds: number) {
  const remainingSeconds = (seconds % 3600) % 60;
  const prettySeconds =
    remainingSeconds > 0
      ? `${remainingSeconds.toString().padStart(2, '0')}s`.padStart(2, '0')
      : '0s';

  return secondsToTimeInHours(seconds) + ` ${prettySeconds}`;
}

export function minutesToTimeInHours(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  const prettyHour = hours > 0 ? `${hours}h` : '';
  const prettyMinutes =
    remainingMinutes > 0 ? `${remainingMinutes.toString().padStart(2, '0')}m` : '';

  return `${prettyHour} ${prettyMinutes}`;
}

export function secondsToHHMMSS(seconds: number) {
  if (seconds < 0) {
    seconds = 0;
  }

  // Convert seconds to minutes and seconds and emit the result
  const formattedSeconds = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');
  const formattedMinutes = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');

  const hours = Math.floor(seconds / 3600);
  const formattedHours = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
}
