import { Component, Input, OnInit } from '@angular/core';
import { AlertDialogButton } from './alert-dialog.interface';
import { CommonModule } from '@angular/common';
import { IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  standalone: true,
  imports: [IonIcon, CommonModule],
})
export class AlertDialogComponent {
  @Input() title: string = '';
  @Input() message: string = '';

  @Input() buttons: AlertDialogButton[] = [];

  @Input() enableBackdropDismiss = true;

  @Input() hideFn: () => void = () => {};

  constructor() {}

  callHandler(handler?: () => void) {
    if (handler) {
      handler();
    }
    this.dismiss();
  }

  backdropDismiss() {
    if (this.enableBackdropDismiss) {
      this.dismiss();
    }
  }

  dismiss() {
    this.hideFn();
  }
}
