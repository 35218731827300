import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  deleteAllEntities,
  getActiveEntity,
  getAllEntities,
  getEntity,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { format, parse } from 'date-fns';
import { BehaviorSubject, Subject, map, tap } from 'rxjs';
import { ELF_PREFIX } from 'src/app/app.constants';
import { TimestampEntity } from 'src/app/timer/state/timer.interface';

export interface HistoryEntity {
  id: string;
  timestamps: TimestampEntity[];
  goal: number | null | undefined; // target goal in minutes of productive time
  multiplier: number; // multiplier for the goal
}

const name = ELF_PREFIX + 'history';

const store = createStore({ name }, withEntities<HistoryEntity>(), withActiveId());

export const persist = persistState(store, {
  key: name,
  storage: localStorageStrategy,
});

@Injectable({
  providedIn: 'root',
})
export class HistoryRepository {
  historyDates$ = store.pipe(selectAllEntities());

  currentTimestamps$ = store.pipe(
    selectActiveEntity(),
    map((entity) => entity?.timestamps || []),
  );

  currentGoal$ = store.pipe(
    selectActiveEntity(),
    map((entity) => entity?.goal),
  );

  currentMultiplier$ = store.pipe(
    selectActiveEntity(),
    map((entity) => entity?.multiplier),
  );

  newEntry$ = new BehaviorSubject<boolean>(false);

  reset$ = new Subject<boolean>();

  constructor() {
    this.selectTodayAsActive();
  }

  addEntry(id: string, entries: TimestampEntity[]) {
    store.update(upsertEntities([{ id, timestamps: entries }]));
    this.selectTodayAsActive();
    this.newEntry$.next(true);
  }

  addSettings(settings: { goal?: number | null; multiplier?: number }, dateKey: string) {
    const entry = store.query(getEntity(dateKey));

    if (entry) {
      store.update(
        upsertEntities({ id: dateKey, ...settings, timestamps: entry.timestamps || [] }),
      );
    } else {
      store.update(upsertEntities([{ id: dateKey, ...settings, timestamps: [] }]));
    }
  }

  updateEntities(dateKey: string, entities: TimestampEntity[]) {
    store.update(upsertEntities({ id: dateKey, timestamps: entities }));
  }

  addFullEntry(entry: HistoryEntity) {
    store.update(upsertEntities([entry]));
    this.selectTodayAsActive();
  }

  getEntries() {
    return store.query(getAllEntities());
  }

  clearAll() {
    // delete all except for todays timer
    const todayEntry = this.getToday();
    store.reset();
    if (todayEntry) {
      this.addFullEntry(todayEntry);
    }
    this.reset$.next(true);
  }

  reset() {
    store.update(deleteAllEntities());
    store.reset();
    this.reset$.next(true);
  }

  getTodayTimestamps() {
    return store.query(getActiveEntity())?.timestamps || [];
  }

  getToday() {
    return store.query(getActiveEntity()) || null;
  }

  getLastUnactiveEntry() {
    const entities = store.query(getAllEntities());
    const activeEntity = store.query(getActiveEntity());

    const unactiveEntities = entities.filter((entity) => entity.id !== activeEntity?.id);
    if (unactiveEntities.length === 0) {
      return null;
    }

    return unactiveEntities[unactiveEntities.length - 1];
  }

  selectTodayAsActive() {
    const today = format(new Date(), 'yyyy-MM-dd');
    store.update(setActiveId(today));
  }
}
