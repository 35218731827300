<div class="c-alert-dialog" (click)="backdropDismiss()">
  <div class="c-alert-dialog__wrapper" (click)="$event.stopPropagation()">
    <div class="c-alert-dialog__title">
      {{ title }}
    </div>
    <div class="c-alert-dialog__description">
      {{ message }}
    </div>
    <div class="c-alert-dialog__buttons" *ngIf="buttons.length > 0">
      <button
        class="c-alert-dialog__button"
        [class.c-alert-dialog__button--cancel]="button.role === 'cancel'"
        [class.c-alert-dialog__button--danger]="button.role === 'danger'"
        *ngFor="let button of buttons"
        (click)="callHandler(button.handler)"
      >
        {{ button.text }}

        <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
      </button>
    </div>
  </div>
</div>
