<div
  class="c-celebration-dialog"
  [class.c-celebration-dialog--visible]="celebrating"
  [class.c-celebration-dialog--success]="variant === 'success'"
  [class.c-celebration-dialog--error]="variant === 'error'"
>
  <div class="c-celebration-dialog__wrapper">
    <div class="c-celebration-dialog__title">
      {{ title }} <ion-icon *ngIf="titleIcon" [name]="titleIcon"></ion-icon>
    </div>
    <div class="c-celebration-dialog__description">
      {{ message }}
    </div>
    <div class="c-celebration-dialog__icon"></div>
  </div>
</div>
