import { Injectable, PlatformRef } from '@angular/core';
import { FirebaseAuthentication, Persistence } from '@capacitor-firebase/authentication';

// Import the functions you need from the SDKs you need
import { getApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import {
  Firestore,
  collection,
  getFirestore,
  setDoc,
  writeBatch,
  doc,
  query,
  where,
  getDocs,
  getDoc,
} from 'firebase/firestore';
import { AuthRepository } from 'src/app/auth/state/auth.repository';
import { UserSettings } from 'src/app/settings/state/settings.interface';

import { Platform } from '@ionic/angular';
import { FirebaseFirestore } from '@capacitor-firebase/firestore';
import { Capacitor } from '@capacitor/core';
import { HistoryEntity, HistoryRepository } from 'src/app/analytics/state/history.repository';
import { addWeeks, format, parse } from 'date-fns';
import { TimestampRepository } from 'src/app/timer/state/timestamps.repository';
import { SettingsRepository } from 'src/app/settings/state/settings.repository';
import { DialogService } from '../dialog/dialog.service';
import { TimestampEntity } from 'src/app/timer/state/timer.interface';
import { TimestampService } from 'src/app/timer/state/timestamps.service';
import { addIcons } from 'ionicons';
import { cloudDownloadOutline } from 'ionicons/icons';

interface FirebaseEntry {
  date: string;
  items: TimestampEntity[];
  metadata: { goal: number | null; multiplier: number };
  analytics?: {};
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  db: Firestore | undefined;

  firebaseAuth: Auth | null = null;

  private syncingHistory = false;

  private didLoginInThisSession = false;

  private saveTimestampDebounceTimer: any = null;

  constructor(
    private auth: AuthRepository,
    private timestamps: TimestampRepository,
    private history: HistoryRepository,
    private settings: SettingsRepository,
    private dialog: DialogService,
  ) {}

  async init() {
    addIcons({ cloudDownloadOutline });

    if (Capacitor.isNativePlatform()) {
      return;
    }
    /**
     * Only needed if the Firebase JavaScript SDK is used.
     *
     * Read more: https://github.com/robingenz/capacitor-firebase/blob/main/packages/authentication/docs/firebase-js-sdk.md
     */
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: 'AIzaSyBDHZH3-RkJo3-qTsFRI1rPFzInXcTFFXI',
      authDomain: 'practical-focus-af6c8.firebaseapp.com',
      databaseURL: 'https://practical-focus-af6c8-default-rtdb.europe-west1.firebasedatabase.app',
      projectId: 'practical-focus-af6c8',
      storageBucket: 'practical-focus-af6c8.appspot.com',
      messagingSenderId: '1095975476716',
      appId: '1:1095975476716:web:2b003aa6fdde5a9da6f4c9',
    };

    // Initialize Firebase
    initializeApp(firebaseConfig);
    this.firebaseAuth = getAuth();
    this.db = getFirestore();

    FirebaseAuthentication.addListener('authStateChange', (change) => {
      // Handle changes in the user's sign-in state
      this.auth.setUser(change.user);
    });

    this.auth.user$.subscribe((user) => {
      if (user) {
        this.listenForTodaysTimestamps();

        // Sync when app starts
        this.syncHistory();

        this.listenForRemoteSettingsChange();
      } else {
        this.didLoginInThisSession = true;
        this.removeListeners();
      }
    });

    this.listenForLocalSettingsChange();
    // Only sync when new entries are added
    this.history.newEntry$.subscribe((newEntry) => {
      if (newEntry) {
        this.syncHistory();
      }
    });
  }

  async syncHistory() {
    if (this.syncingHistory) {
      return;
    }
    this.syncingHistory = true;
    const uid = this.auth.getUser()?.uid;
    if (!uid) {
      console.error('No user id found for syncing history');
      return;
    }

    try {
      // Fetch remote history entries
      const remoteHistoryEntries = await this.fetchRemoteHistory(uid);
      const localHistoryEntries = this.history.getEntries();

      // Adds remote items to local
      remoteHistoryEntries.forEach((remoteEntry) => {
        if (remoteEntry && remoteEntry.items.length) {
          this.history.addFullEntry({
            id: remoteEntry.date,
            timestamps: remoteEntry.items,
            goal: remoteEntry.metadata.goal || null,
            multiplier: remoteEntry.metadata.multiplier || 5,
          });
        }
      });

      // Sync from local to remote using batch
      const batch = writeBatch(this.db!);
      localHistoryEntries.forEach((localEntry) => {
        const remoteEntry = remoteHistoryEntries.find((re) => re.date === localEntry.id);
        if (!remoteEntry || remoteEntry.items.length <= localEntry.timestamps.length) {
          // update remote entry
          const newEntryRef = doc(this.db!, `timer/${uid}/entries/${localEntry.id}`);

          const entryToSave: FirebaseEntry = {
            date: localEntry.id,
            items: localEntry.timestamps,
            metadata: { goal: localEntry.goal || null, multiplier: localEntry.multiplier || 5 },
          };

          batch.set(newEntryRef, entryToSave);
        }
      });
      await batch.commit();
    } catch (error) {
      console.error('Error syncing history:', error);
    }

    this.syncingHistory = false;
  }

  async setUserSettings(settings: UserSettings) {
    const user = this.auth.getUser();
    const uid = user?.uid;

    if (!uid) {
      throw new Error('No user id found');
    }

    FirebaseFirestore.setDocument({
      reference: `user_settings/${uid}`,
      data: { ...settings },
      merge: true,
    });
  }

  async logout() {
    await this.removeListeners();
    await FirebaseAuthentication.signOut();
    this.history.clearAll();
  }

  // TODO: Reattach listener in case today changes
  listenForTodaysTimestamps() {
    if (!this.auth.isAuthenticated()) {
      return;
    }

    const uid = this.auth.getUser()!.uid;

    const today = format(new Date(), 'yyyy-MM-dd');
    FirebaseFirestore.addDocumentSnapshotListener(
      {
        reference: `timer/${uid}/entries/${today}`,
      },
      (data) => {
        const todayData = data?.snapshot.data as FirebaseEntry;

        // Show sync dialog
        if (this.didLoginInThisSession && this.auth.isAuthenticated()) {
          this.dialog.showToast({
            title: 'Logged In!',
            message: 'Your timer is now available across devices',
            duration: 4000,
            icon: 'checkmark-circle',
          });
          this.didLoginInThisSession = false;
        }

        if (!todayData) {
          this.saveTodaysTimestamp();
          return;
        }

        const remoteEntries = todayData.items;
        const localData = this.timestamps.getEntries();

        // Check if remote entries contain all local entries and have additional entries
        const isSuperset = localData.every((localEntry) =>
          remoteEntries.some(
            (remoteEntry) =>
              remoteEntry.id === localEntry.id && remoteEntry.timestamp === localEntry.timestamp,
          ),
        );

        if ((isSuperset && remoteEntries.length >= localData.length) || localData.length <= 1) {
          this.history.addFullEntry({
            id: today,
            timestamps: remoteEntries,
            goal: todayData.metadata.goal || null,
            multiplier: todayData.metadata.multiplier || 5,
          });
        } else {
          // add debug info here
          this.dialog.showAlert({
            title: 'Hey, your timer is out of sync',
            message:
              "It looks like your timer from this device is out of sync with other devices! Would you like to keep the timer from this device or switch to the cloud data for today? Please note, this change is permanent and only affects today's data.",
            enableBackdropDismiss: false,
            buttons: [
              {
                text: 'Use Cloud',
                icon: 'cloud-download-outline',
                role: 'cancel',
                handler: () => {
                  this.history.addFullEntry({
                    id: today,
                    timestamps: remoteEntries,
                    goal: todayData.metadata.goal || null,
                    multiplier: todayData.metadata.multiplier || 5,
                  });
                },
              },
              {
                text: 'Use This Device',
                role: 'danger',
                handler: async () => {
                  const firebaseEntryData: FirebaseEntry = {
                    date: today,
                    items: localData,
                    metadata: {
                      goal: this.timestamps.getTodaysGoal().goal || null,
                      multiplier: this.timestamps.getMultiplier() || 5,
                    },
                  };
                  await this.updateFirebaseEntry(firebaseEntryData, uid, today);
                },
              },
            ],
          });
        }

        const localMultiplier = this.timestamps.getMultiplier();
        const localGoal = this.timestamps.getTodaysGoal().goal;

        if (localMultiplier !== todayData.metadata.multiplier) {
          // update local multiplier in case remote has changed
          this.history.addSettings({ multiplier: todayData.metadata.multiplier || 5 }, today);
        }

        if (localGoal !== todayData.metadata.goal) {
          this.history.addSettings({ goal: todayData.metadata.goal || null }, today);
        }
      },
    );
  }

  async fetchRemoteHistory(userId: string): Promise<FirebaseEntry[]> {
    try {
      const today = format(new Date(), 'yyyy-MM-dd');
      const data = query(collection(this.db!, `timer/${userId}/entries`));

      const querySnapshot = await getDocs(data);
      const docs = querySnapshot.docs.map((doc) => doc.data());

      if (docs.length) {
        return docs as FirebaseEntry[];
      }

      return [];
    } catch (error) {
      console.error('Error fetching history:', error);
    }

    return [];
  }

  async saveTodaysTimestamp(forceUpload = false) {
    clearTimeout(this.saveTimestampDebounceTimer);
    this.saveTimestampDebounceTimer = setTimeout(async () => {
      if (!this.auth.isAuthenticated()) {
        return;
      }

      const uid = this.auth.getUser()!.uid;
      const todayEntry = this.history.getToday() || null;

      const entryKey = todayEntry ? todayEntry.id : format(new Date(), 'yyyy-MM-dd');

      // Fetch today's remote entries to compare with local entries
      const todayDoc = await FirebaseFirestore.getDocument({
        reference: `timer/${uid}/entries/${entryKey}`,
      });

      const data = todayDoc.snapshot.data as FirebaseEntry;

      const remoteEntries = data?.items || [];
      const localEntries = todayEntry?.timestamps || [];

      // Check if all remote entries are present in the local entries with matching id and timestamp
      const isSubset = remoteEntries.every((remoteEntry) =>
        localEntries.some(
          (localEntry) =>
            remoteEntry.id === localEntry.id && remoteEntry.timestamp === localEntry.timestamp,
        ),
      );

      const firebaseEntryData: FirebaseEntry = {
        date: entryKey,
        items: localEntries,
        metadata: {
          goal: this.timestamps.getTodaysGoal().goal || null,
          multiplier: this.timestamps.getMultiplier() || 5,
        },
      };

      if (isSubset || remoteEntries.length === 0 || forceUpload) {
        this.updateFirebaseEntry(firebaseEntryData, uid, entryKey);
      } else {
        this.dialog.showAlert({
          title: 'Hey, your timer is out of sync',
          message:
            "It looks like your timer from this device is out of sync with other devices! Would you like to keep the timer from this device or switch to the cloud data for today? Please note, this change is permanent and only affects today's data.",
          enableBackdropDismiss: false,
          buttons: [
            {
              text: 'Use Cloud',
              icon: 'cloud-download-outline',
              role: 'cancel',
              handler: () => {
                this.history.addFullEntry({
                  id: entryKey,
                  timestamps: remoteEntries,
                  goal: firebaseEntryData.metadata.goal,
                  multiplier: firebaseEntryData.metadata.multiplier,
                });
              },
            },
            {
              text: 'Use This Device',
              role: 'danger',
              handler: async () => {
                await this.updateFirebaseEntry(firebaseEntryData, uid, entryKey);
              },
            },
          ],
        });
      }
    }, 200);
  }

  async updateFirebaseEntry(entryData: FirebaseEntry, uid: string, entryKey: string) {
    try {
      await FirebaseFirestore.setDocument({
        reference: `timer/${uid}/entries/${entryKey}`,
        data: entryData,
        merge: false,
      });
    } catch (err) {
      console.error('Error saving todays timestamp:', err);
    }
  }

  async deleteAccount() {
    if (!this.auth.isAuthenticated()) {
      return;
    }
    const uid = this.auth.getUser()!.uid;

    await FirebaseFirestore.deleteDocument({
      reference: `user_settings/${uid}`,
    });

    await FirebaseFirestore.deleteDocument({
      reference: `timer/${uid}`,
    });

    await this.firebaseAuth?.currentUser?.delete();

    this.auth.setUser(null);
    this.history.reset();
  }

  private listenForRemoteSettingsChange() {
    if (!this.auth.isAuthenticated()) {
      return;
    }

    const uid = this.auth.getUser()!.uid;

    FirebaseFirestore.addDocumentSnapshotListener(
      {
        reference: `user_settings/${uid}`,
      },
      (data) => {
        const settings = data?.snapshot.data as UserSettings;

        // checks if the settings are valid and different
        const userSettings = this.settings.getUserSettings();

        // Checks if the objects are the same
        // Uses sort as Firebase changes the order of the attributes
        if (
          settings &&
          userSettings &&
          JSON.stringify(Object.entries(settings).sort()) !==
            JSON.stringify(Object.entries(userSettings).sort())
        ) {
          this.settings.setUserSettings(settings);
        }
      },
    );
  }

  private listenForLocalSettingsChange() {
    this.settings.manualUserSettingsChange$.subscribe(async (settings) => {
      if (!this.auth.isAuthenticated()) {
        return;
      }

      const uid = this.auth.getUser()!.uid;

      await FirebaseFirestore.setDocument({
        reference: `user_settings/${uid}`,
        data: { ...settings },
        merge: true,
      });
    });
  }

  private removeListeners() {
    return FirebaseFirestore.removeAllListeners();
  }
}
