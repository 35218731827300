// timer.service.ts
import { Injectable } from '@angular/core';
import { addSeconds, differenceInMinutes, differenceInSeconds, isAfter, isToday } from 'date-fns';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { finalize, takeWhile } from 'rxjs/operators';
import { SettingsRepository } from 'src/app/settings/state/settings.repository';
import { TimestampRepository } from './timestamps.repository';
import { TimerAction } from './timer.interface';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { secondsToHHMMSS } from 'src/app/utilities/data-formatting';
import { TimestampService } from './timestamps.service';

export enum Mode {
  Focus = 'focus',
  Rest = 'rest',
  Stop = 'stopped',
  Ended = 'ended',
}

/**
 * TODOS:
 * - Use timestamp instead of timer -> Fixes issue when tab is in background;
 * - Save settings in LocalStorage
 * - Expose Reminder Settings
 * - Refactor for cleaness and testing
 */

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  formattedTime$ = new BehaviorSubject<string>('00:00');

  private remainingRestSeconds = 0;
  private totalRestSeconds = 0;

  private timerSubscription: Subscription | null = null;
  private reminderSubscription: Subscription | null = null;

  private restShouldEndAt: Date | null = null;

  private restAudio: HTMLAudioElement;

  private actionRunning: TimerAction = TimerAction.Pause;

  constructor(
    private settings: SettingsRepository,
    private timestamps: TimestampRepository,
    private timestampService: TimestampService,
    private firebase: FirebaseService,
    private notification: NotificationService,
  ) {
    this.settings.focusReminderInterval$.subscribe((interval) => {
      // updates focus reminder timing when the interval changes
      this.setFocusReminders();
    });

    this.handleExtension();

    this.checkAndPauseTimer();

    this.startAliveHeartbeat();

    this.restAudio = new Audio('assets/audios/rest_end.mp3');

    this.timestamps.lastEntry$.subscribe((entry) => {
      if (!entry) {
        return;
      }
      // Prevents double actions
      if (this.actionRunning !== entry.action) {
        this.actionRunning = entry.action;
      } else {
        return;
      }

      switch (entry.action) {
        case TimerAction.Focus:
          this.handleStartFocus();
          break;
        case TimerAction.Rest:
          this.handleStartRest();
          break;
        case TimerAction.Pause:
          this.handlePauseTimer();
          break;
        case TimerAction.Resume:
          this.handleResume();
          break;
        case TimerAction.Stop:
          this.handleStopTimer();
          break;
      }
    });
  }

  startFocus(resuming = false) {
    if (!resuming) {
      this.stopTimer();
      this.timestampService.addEntryAndSync(TimerAction.Focus);
    }
  }

  startRest(resuming = false) {
    if (!resuming) {
      this.stopTimer();
      this.timestampService.addEntryAndSync(TimerAction.Rest);
    }
  }

  togglePause() {
    const paused = this.timestamps.isPaused();
    if (!paused) {
      this.pauseTimer();
    } else {
      this.resumeTimer();
    }
    this.firebase.saveTodaysTimestamp();
  }

  pauseTimer() {
    this.timestampService.addEntryAndSync(TimerAction.Pause);
  }

  resumeTimer() {
    this.timestampService.addEntryAndSync(TimerAction.Resume);
  }

  stopTimer() {
    // Reset the total rest seconds with the remaining from the last round;
    // This allows the user to stop the rest prior to ending

    if (this.reminderSubscription || this.timerSubscription) {
      this.timestampService.addEntryAndSync(TimerAction.Stop);
    }
  }

  getRemainingRest(): number {
    return this.remainingRestSeconds;
  }

  private emitFormattedTime(seconds: number) {
    if (seconds < 0) {
      seconds = 0;
    }

    const formatted = secondsToHHMMSS(seconds);

    this.formattedTime$.next(formatted);
  }

  private setFocusReminders() {
    this.clearReminderNotifications();
    const mode = this.timestamps.getCurrentMode();
    if (mode !== TimerAction.Focus || this.timestamps.isPaused()) {
      return;
    }

    // create an array of notifications to be sent, every 5 minutes
    // use a timestamp to determine the time for each notification
    // fire the notification when the timestamp has passed by and remove it from the array
    const notifications = new Array(360).fill(0).map((_, index) => {
      return {
        timestamp:
          new Date().getTime() + 1000 * 60 * this.settings.getFocusReminderInterval() * (index + 1),
        message: this.getRandomReminder(),
      };
    });

    this.reminderSubscription = timer(1000, 1000).subscribe(() => {
      const nextNotification = notifications[0];

      if (nextNotification && isAfter(new Date(), nextNotification.timestamp)) {
        // removes the next one from the array
        notifications.shift();

        if (this.settings.isEndTimerReminderEnabled()) {
          this.notification.notify(nextNotification.message, 5000);
        }
      }
    });
  }

  private getRandomReminder() {
    const reminders = this.settings.getRemindersList();
    const randomIndex = Math.floor(Math.random() * reminders.length);
    return reminders[randomIndex];
  }

  private startAliveHeartbeat() {
    timer(1000, 1000).subscribe(() => {
      this.settings.setLastAliveTimestamp();
    });
  }

  private clearReminderNotifications() {
    if (this.reminderSubscription) {
      this.reminderSubscription.unsubscribe();
      this.reminderSubscription = null;
    }
  }

  private clearTimerSubscription() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  private handleResume() {
    const mode = this.timestamps.getCurrentMode();
    switch (mode) {
      case TimerAction.Focus:
        this.handleStartFocus();
        break;
      case TimerAction.Rest:
        this.totalRestSeconds = this.remainingRestSeconds;
        this.handleStartRest();
        break;
    }
  }

  private handleStartFocus() {
    // we need to handle stop in case there are several windows opened
    this.handleStopTimer();

    this.restShouldEndAt = null;
    this.setFocusReminders();

    // start timer whenever the clock is at 0ms
    const currentMs = new Date().getMilliseconds();
    const msUntilNextFullSecond = 1000 - currentMs;

    this.timerSubscription = timer(msUntilNextFullSecond, 1000).subscribe(() => {
      this.emitFormattedTime(this.timestamps.getCurrentFocusedSeconds());
    });
  }

  private handleStartRest() {
    // we need to handle stop in case there are several windows opened
    this.handleStopTimer();

    this.remainingRestSeconds = this.totalRestSeconds;

    this.restShouldEndAt = addSeconds(new Date(), this.remainingRestSeconds);

    const currentMs = new Date().getMilliseconds();
    const msUntilNextFullSecond = 1000 - currentMs;

    this.timerSubscription = timer(msUntilNextFullSecond, 1000)
      .pipe(
        takeWhile(() => this.remainingRestSeconds > 0),
        finalize(() => {
          this.clearTimerSubscription();

          this.emitFormattedTime(this.remainingRestSeconds);

          if (this.remainingRestSeconds <= 0) {
            const restEndTime = addSeconds(
              this.timestamps.getLastRestStartTimestamp()!,
              this.totalRestSeconds,
            );

            const timestamp = restEndTime ?? new Date();
            this.timestampService.addEntryAndSync(TimerAction.Stop, timestamp.toString());

            if (this.settings.isEndTimerReminderEnabled()) {
              this.notification.notify('Rest Finished');
              if (this.settings.isSoundEnabled() && this.restAudio.readyState === 4) {
                this.restAudio.play();
              }
            }
          }
        }),
      )
      .subscribe(() => {
        // calculate the remaining rest seconds based on the start Time and totalRest time
        this.remainingRestSeconds =
          this.totalRestSeconds -
          differenceInSeconds(new Date(), this.timestamps.getLastRestStartTimestamp()!);

        this.emitFormattedTime(this.remainingRestSeconds);
      });
  }

  private handlePauseTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    this.clearReminderNotifications();

    this.emitCurrentTime();
  }

  private handleStopTimer() {
    this.clearReminderNotifications();

    this.updateTotalRest();

    this.clearTimerSubscription();
  }

  private checkAndPauseTimer() {
    // Pauses last timer at the timestamp in which the app was closed if it was more than 10 minutes ago
    const lastAliveAt = this.settings.getLastAliveTimestamp();

    if (lastAliveAt) {
      const now = new Date();
      const lastAliveDate = new Date(lastAliveAt);
      const minutesSinceLastAlive = differenceInMinutes(now, lastAliveDate);

      if (minutesSinceLastAlive >= 10 && isToday(lastAliveAt)) {
        this.timestampService.addEntryAndSync(TimerAction.Pause, lastAliveDate.toString());
      }

      if (this.settings.getPreserveTimer()) {
        // Runs if the app opens but there were an active timer running
        this.emitCurrentTime();
      }
    }
  }

  private updateTotalRest() {
    if (!this.settings.getAccumulateRest()) {
      this.totalRestSeconds =
        this.timestamps.getCurrentFocusedSeconds() / this.timestamps.getMultiplier();
    } else {
      this.totalRestSeconds =
        this.timestamps.getTodaysTime(TimerAction.Focus) / this.timestamps.getMultiplier() -
        this.timestamps.getTodaysTime(TimerAction.Rest);
    }
  }

  private emitCurrentTime() {
    const mode = this.timestamps.getCurrentMode();

    if (mode === TimerAction.Focus || mode === TimerAction.Rest) {
      if (mode === TimerAction.Focus) {
        this.emitFormattedTime(this.timestamps.getCurrentFocusedSeconds());
      } else {
        this.updateTotalRest();
        this.remainingRestSeconds = this.totalRestSeconds;
        this.emitFormattedTime(this.remainingRestSeconds);
      }
    }
  }

  private handleExtension() {
    /*  window.addEventListener('message', (event) => {
      if (event.data.requestFocus && this.timestamps.getCurrentMode() === TimerAction.Stop) {
        this.startFocus();
        event.source?.postMessage({ focusStarted: true });
      }
    }); */

    this.settings.websiteBlockerEnabled$.subscribe((isEnabled) => {
      if (!isEnabled && window && window.postMessage) {
        window.postMessage({ mode: 'rest' });
        return;
      }
    });

    this.formattedTime$.subscribe((ellapsedTime) => {
      if (!this.settings.isWebsiteBlockerEnabled()) {
        window.postMessage({ enabled: false });
        return;
      }

      const mode = this.timestamps.getCurrentMode();
      try {
        if (mode === TimerAction.Focus && window && window.postMessage) {
          const whitelist = this.settings.getWhitelistItems() || '';
          window.postMessage({
            enabled: true,
            mode: 'focus',
            ellapsedTime,
            whitelist,
            hyperBlockEnabled: this.settings.getHyperBlockEnabled(),
          });
        } else {
          window.postMessage({ enabled: true, mode: 'rest' });
        }
      } catch (e) {}
    });
  }
}
