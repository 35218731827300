/**
 * A service for controlling the timestamps for today
 */

import { Injectable } from '@angular/core';
import { TimestampRepository } from './timestamps.repository';
import { HistoryRepository } from 'src/app/analytics/state/history.repository';
import { differenceInSeconds, format, getSeconds } from 'date-fns';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { TimerAction } from './timer.interface';

@Injectable({
  providedIn: 'root',
})
export class TimestampService {
  constructor(
    private timestamps: TimestampRepository,
    private history: HistoryRepository,
    private firebase: FirebaseService,
  ) {}

  setMultiplier(multiplier: number) {
    const today = format(new Date(), 'yyyy-MM-dd');

    this.history.addSettings(
      { multiplier: multiplier, goal: this.timestamps.getTodaysGoal().goal },
      today,
    );

    this.firebase.saveTodaysTimestamp();

    // TODO: sync with firebase
  }

  getMultiplier() {
    return this.timestamps.getMultiplier();
  }

  //Goals
  setGoal(goalsInMinutes: number) {
    const today = format(new Date(), 'yyyy-MM-dd');

    this.history.addSettings(
      { goal: goalsInMinutes, multiplier: this.timestamps.getMultiplier() },
      today,
    );

    this.firebase.saveTodaysTimestamp();
  }

  addEntryAndSync(action: TimerAction, timestamp: string = new Date().toString()) {
    const entities = this.history.getTodayTimestamps();

    const lastEntry = entities.slice(-1)[0];

    // Do not add the same action twice
    if (lastEntry && lastEntry?.action === action) {
      return;
    }

    // sort all entities by id
    let id = new Date().getTime();
    // checks if there is any entry with the same id
    const hasSameId = entities.find((entity) => entity.id === id);

    if (hasSameId) {
      id++;
    }

    entities.push({ timestamp, action, id });

    const today = format(new Date(), 'yyyy-MM-dd');
    this.history.updateEntities(today, entities);

    this.firebase.saveTodaysTimestamp();
  }

  deleteEntry(entryId: number) {
    const today = format(new Date(), 'yyyy-MM-dd');
    const todaysEntities = this.history.getTodayTimestamps();
    const newEntities = todaysEntities.filter((entity) => entity.id !== entryId);
    this.history.updateEntities(today, newEntities);

    this.firebase.saveTodaysTimestamp(true);
  }

  updateEntryTime(oldId: number, newTime: string | null) {
    if (!newTime) {
      return;
    }

    const today = format(new Date(), 'yyyy-MM-dd');
    const todaysEntities = this.history.getTodayTimestamps();

    // finds index of entity to be edited (usually the last one)
    const entityIndex = todaysEntities.findIndex((entity) => entity.id === oldId);

    if (entityIndex === -1) {
      return;
    }

    const entity = todaysEntities[entityIndex];
    let stopEntity = null; // needs to update this time as well
    let referenceEntity = todaysEntities[entityIndex - 1]; // refenrece time, can't go earlier than this

    // checks if there is a stop entity just before (or an reference entity)
    if (todaysEntities.length > 1 && todaysEntities[entityIndex - 1].action === TimerAction.Stop) {
      stopEntity = todaysEntities[entityIndex - 1];
      // if the stop entity is more than 2 seconds away from the entity, it is not a valid stop entity
      if (differenceInSeconds(stopEntity.timestamp, entity.timestamp) > 2) {
        stopEntity = null;
      }
      referenceEntity = todaysEntities[entityIndex - 2];
    }

    const [hours, minutes] = newTime.split(':');
    const newTimestamp = new Date(entity.timestamp);
    newTimestamp.setHours(parseInt(hours, 10));
    newTimestamp.setMinutes(parseInt(minutes, 10));
    newTimestamp.setSeconds(0);

    if (referenceEntity) {
      const referenceSeconds = getSeconds(referenceEntity.timestamp);
      newTimestamp.setSeconds(referenceSeconds + 1);
    }

    if (stopEntity) {
      stopEntity.timestamp = newTimestamp.toString();
    }

    const newId = newTimestamp.getTime();
    const newEntities = todaysEntities.map((en) => {
      if (en.id === stopEntity?.id) {
        // TODO: Fix the -1
        return { ...stopEntity, timestamp: stopEntity.timestamp, id: newId - 1 };
      }

      if (en.id === entity.id) {
        return { ...entity, timestamp: newTimestamp.toString(), id: newId };
      }
      return en;
    });

    console.table(newEntities);

    this.history.updateEntities(today, newEntities);
    this.firebase.saveTodaysTimestamp(true);
  }
}
