import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  standalone: true,
  imports: [IonIcon, CommonModule],
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() title: string | undefined;
  @Input() message: string | undefined;
  @Input() titleIcon: string | undefined;
  @Input() celebrating: boolean | undefined;
  @Input() variant: 'default' | 'success' | 'error' | undefined;

  constructor() {}
}
