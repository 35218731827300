import {
  Injectable,
  ApplicationRef,
  Injector,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
} from '@angular/core';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog/alert-dialog.component';
import { AlertDialogButton } from 'src/app/shared/components/alert-dialog/alert-dialog/alert-dialog.interface';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  isShowingDialog = false;
  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  showToast(params: {
    title: string;
    message: string;
    icon?: string;
    duration?: number;
    variant?: 'default' | 'success' | 'error';
  }): ComponentRef<DialogComponent> {
    // Create a component reference from the component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(this.injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Set the properties on the instance
    componentRef.instance.title = params.title;
    componentRef.instance.message = params.message;
    componentRef.instance.titleIcon = params.icon;
    componentRef.instance.variant = params.variant;
    componentRef.instance.celebrating = true;

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    const duration = params.duration || 4000;

    setTimeout(() => {
      componentRef.instance.celebrating = false;
    }, duration);

    // Automatically hide after a few seconds
    setTimeout(() => {
      this.hideDialog(componentRef);
    }, duration + 500); // Adjust time as needed

    return componentRef;
  }

  showAlert(params: {
    title: string;

    message?: string;
    enableBackdropDismiss?: boolean;
    buttons?: AlertDialogButton[];
  }): ComponentRef<AlertDialogComponent> | null {
    if (this.isShowingDialog) {
      return null;
    }
    this.isShowingDialog = true;
    // Create a component reference from the component
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(AlertDialogComponent);
    const componentRef = componentFactory.create(this.injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Set the properties on the instance
    componentRef.instance.title = params.title;
    componentRef.instance.message = params.message || '';
    componentRef.instance.buttons = params.buttons || [];
    componentRef.instance.enableBackdropDismiss = params.enableBackdropDismiss ?? true;
    componentRef.instance.hideFn = () => {
      this.isShowingDialog = false;
      this.hideDialog(componentRef);
    };

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    return componentRef;
  }

  private hideDialog(componentRef: ComponentRef<DialogComponent | AlertDialogComponent>) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
