import { Injectable } from '@angular/core';
import { SettingsRepository } from 'src/app/settings/state/settings.repository';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private settings: SettingsRepository) {}

  init() {
    this.updateNotificationPermission();
  }

  async notify(message: string, duration: number | null = null) {
    if (!this.settings.isNotificationEnabled()) {
      return;
    }

    if (!this.isNotificationSupported()) {
      return;
    }

    let permission = Notification.permission;

    // Check whether notification permissions have already been granted
    if (Notification.permission !== 'granted') {
      permission = await Notification.requestPermission();
      // If it's okay let's create a notification
    }
    // If the user accepts, let's create a notification
    if (permission === 'granted') {
      var notification = new Notification(message);
      if (duration) {
        setTimeout(() => {
          notification.close();
        }, duration); // close the notification after 5 seconds
      }
    }
  }

  async enableNotifications() {
    if (!this.isNotificationSupported()) {
      return;
    }

    const permission = await Notification.requestPermission();
    this.settings.setNotificationEnabled(permission === 'granted');
  }

  async toggleNotifications() {
    if (!this.isNotificationSupported()) {
      return;
    }

    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      this.settings.toggleNotification();
    } else {
      // TODO; Show a message to the user that they need to enable notifications
      alert('Please enable notifications in your browser settings');
    }
  }

  /**
   * The user might disable the notification for the website.
   * In this case, we want to make sure the Notification Setting always reflect the correct state
   */
  private updateNotificationPermission() {
    if (!this.isNotificationSupported()) {
      return;
    }

    if (Notification.permission === 'denied') {
      this.settings.setNotificationEnabled(false);
    }
  }

  private isNotificationSupported() {
    return 'Notification' in window;
  }
}
