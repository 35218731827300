import { Injectable } from '@angular/core';
import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';

import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { isToday } from 'date-fns';
import { ELF_PREFIX } from 'src/app/app.constants';
import { UserSettings } from './settings.interface';
import { Subject, tap } from 'rxjs';

interface LocalSettingsProps extends UserSettings {
  notificationEnabled: boolean;

  focusTimerReminders: string[];

  accumulateRest: boolean;
  preserveTimer: boolean;
  lastAliveTimestamp: number;

  settingsCardExpanded: boolean;

  isMenuVisible: boolean;
  isSidebarItemVisible: { [key: string]: boolean };
  onboardingCompleted: boolean;
  websiteBlockerEnabled: boolean;
  webBlockerRequirementsVisible: boolean;

  whitelistItems: string;
  hyperBlockEnabled: boolean;
}

const initialState: LocalSettingsProps = {
  notificationEnabled: false,
  focusReminderEnabled: true,
  endTimerReminderEnabled: true,
  focusReminderInterval: 5,
  accumulateRest: true,
  preserveTimer: true,
  settingsCardExpanded: true,
  restSoundEnabled: true,
  focusTimerReminders: [
    'Stay sharp, stay bright. Keep the focus tight!',
    "Flowing and growing, every moment's a chance!",
    'Eyes on the prize, make this time your dance.',
    "Breathe in focus, exhale success. You've got this!",
    "Ride the wave of productivity. Surf's up!",
    'Unlock your potential, one tick at a time.',
    'Crafting greatness, stitch by stitch.',
    "Keep the momentum, you're on a roll!",
    'Every minute counts. Make it matter.',
    'Focus is your superpower. Wear it well.',
  ],
  lastAliveTimestamp: Date.now(),
  shouldCelebrate: true,
  isMenuVisible: false,
  isSidebarItemVisible: {},
  onboardingCompleted: false,
  websiteBlockerEnabled: false,
  webBlockerRequirementsVisible: true,
  whitelistItems: '',
  hyperBlockEnabled: false,
};

const name = ELF_PREFIX + 'settings';

const store = createStore({ name }, withProps<LocalSettingsProps>(initialState));

export const persist = persistState(store, {
  key: name,
  storage: localStorageStrategy,
});

@Injectable({
  providedIn: 'root',
})
export class SettingsRepository {
  notificationEnabled$ = store.pipe(select((state) => state.notificationEnabled));

  focusReminderEnabled$ = store.pipe(select((state) => state.focusReminderEnabled));
  focusReminderInterval$ = store.pipe(select((state) => state.focusReminderInterval));

  endTimerReminderEnabled$ = store.pipe(select((state) => state.endTimerReminderEnabled));
  restSoundEnabled$ = store.pipe(select((state) => state.restSoundEnabled));

  accumulateRest$ = store.pipe(select((state) => state.accumulateRest));

  preserveTimer$ = store.pipe(select((state) => state.preserveTimer));

  settingsExpanded$ = store.pipe(select((state) => state.settingsCardExpanded));

  celebrationEnabled$ = store.pipe(select((state) => state.shouldCelebrate));

  isMenuVisible$ = store.pipe(select((state) => state.isMenuVisible));

  manualUserSettingsChange$ = new Subject<UserSettings>();

  isSidebarItemVisible$ = store.pipe(select((state) => state.isSidebarItemVisible));

  isOnboardingCompleted$ = store.pipe(select((state) => state.onboardingCompleted));

  websiteBlockerEnabled$ = store.pipe(select((state) => state.websiteBlockerEnabled));
  isWebBlockerRequirementsVisible$ = store.pipe(
    select((state) => state.webBlockerRequirementsVisible),
  );
  whitelistItems$ = store.pipe(select((state) => state.whitelistItems));
  hyperBlockEnabled$ = store.pipe(select((state) => state.hyperBlockEnabled));

  constructor() {}

  // General Notifications
  setNotificationEnabled(notificationEnabled: boolean) {
    store.update(setProp('notificationEnabled', notificationEnabled));
  }

  isNotificationEnabled() {
    return store.query((state) => state.notificationEnabled);
  }

  toggleNotification() {
    const notificationEnabled = store.query((state) => state.notificationEnabled);
    store.update(setProp('notificationEnabled', !notificationEnabled));
    this.emitUserSettings();
  }

  // Focus reminders
  toggleFocusReminder() {
    const focusReminderEnabled = store.query((state) => state.focusReminderEnabled);
    store.update(setProp('focusReminderEnabled', !focusReminderEnabled));
    this.emitUserSettings();
  }

  isFocusReminderEnabled() {
    return store.query((state) => state.focusReminderEnabled);
  }

  setFocusReminderInterval(interval: number) {
    store.update(setProp('focusReminderInterval', interval));
    this.emitUserSettings();
  }

  getFocusReminderInterval() {
    return store.query((state) => state.focusReminderInterval);
  }

  getRemindersList() {
    return store.query((state) => state.focusTimerReminders);
  }

  // End Timer Reminder
  toggleEndTimerReminder() {
    const endTimerReminderEnabled = store.query((state) => state.endTimerReminderEnabled);
    store.update(setProp('endTimerReminderEnabled', !endTimerReminderEnabled));
    this.emitUserSettings();
  }

  isEndTimerReminderEnabled() {
    return store.query((state) => state.endTimerReminderEnabled);
  }

  toggleEndRestSound() {
    const soundEnabled = store.query((state) => state.restSoundEnabled);
    store.update(setProp('restSoundEnabled', !soundEnabled));
    this.emitUserSettings();
  }

  isSoundEnabled() {
    return store.query((state) => state.restSoundEnabled);
  }

  //Accumulate Rest
  toggleAccumulateRest() {
    const accumulateRest = store.query((state) => state.accumulateRest);
    store.update(setProp('accumulateRest', !accumulateRest));
  }

  getAccumulateRest() {
    return store.query((state) => state.accumulateRest);
  }

  //Preserve Timer
  togglePreserveTimer() {
    const preserveTimer = store.query((state) => state.preserveTimer);
    store.update(setProp('preserveTimer', !preserveTimer));
  }

  getPreserveTimer() {
    return store.query((state) => state.preserveTimer);
  }

  // Alive Timestamp
  setLastAliveTimestamp(timestamp: number = Date.now()) {
    store.update(setProp('lastAliveTimestamp', timestamp));
  }

  getLastAliveTimestamp() {
    return store.query((state) => state.lastAliveTimestamp);
  }

  // Expanded
  toggleExpanded() {
    const expanded = store.query((state) => state.settingsCardExpanded);
    store.update(setProp('settingsCardExpanded', !expanded));
  }

  //Celebration
  toggleCelebration() {
    const shouldCelebrate = store.query((state) => state.shouldCelebrate);
    store.update(setProp('shouldCelebrate', !shouldCelebrate));
    this.emitUserSettings();
  }

  isCelebrationEnabled() {
    return store.query((state) => state.shouldCelebrate);
  }

  // Menu
  toggleMenu() {
    const isMenuVisible = store.query((state) => state.isMenuVisible);
    store.update(setProp('isMenuVisible', !isMenuVisible));
  }

  isMenuVisible() {
    return store.query((state) => state.isMenuVisible);
  }

  // Sidebar Item
  toggleSidebarItem(id: string) {
    const sidebarItemVisible = store.query((state) => state.isSidebarItemVisible);
    const isSidebarItemVisible =
      sidebarItemVisible[id] === undefined ? true : sidebarItemVisible[id];

    store.update(
      setProp('isSidebarItemVisible', { ...sidebarItemVisible, [id]: !isSidebarItemVisible }),
    );
  }

  // website blocker
  toggleWebsiteBlocker() {
    const websiteBlockerEnabled = store.query((state) => state.websiteBlockerEnabled);
    store.update(setProp('websiteBlockerEnabled', !websiteBlockerEnabled));
  }

  isWebsiteBlockerEnabled() {
    return store.query((state) => state.websiteBlockerEnabled);
  }

  toggleWebBlockerRequirements() {
    const webBlockerRequirementsVisible = store.query(
      (state) => state.webBlockerRequirementsVisible,
    );
    store.update(setProp('webBlockerRequirementsVisible', !webBlockerRequirementsVisible));
  }

  setWhitelistItems(whitelistItems: string) {
    store.update(setProp('whitelistItems', whitelistItems));
  }

  getWhitelistItems() {
    return store.query((state) => state.whitelistItems);
  }

  toggleHyperBlock() {
    const hyperBlockEnabled = store.query((state) => state.hyperBlockEnabled);
    store.update(setProp('hyperBlockEnabled', !hyperBlockEnabled));
  }

  getHyperBlockEnabled() {
    return store.query((state) => state.hyperBlockEnabled);
  }

  // setOnboardingCompleted
  setOnboardingCompleted(completed: boolean) {
    store.update(setProp('onboardingCompleted', completed));
  }

  getUserSettings(): UserSettings {
    const state = store.query((state) => state);
    return {
      focusReminderEnabled: state.focusReminderEnabled,
      endTimerReminderEnabled: state.endTimerReminderEnabled,
      restSoundEnabled: state.restSoundEnabled,
      focusReminderInterval: state.focusReminderInterval,
      shouldCelebrate: state.shouldCelebrate,
    };
  }

  setUserSettings(settings: Partial<UserSettings>) {
    store.update(setProps((state) => ({ ...state, ...settings })));
  }

  private emitUserSettings() {
    this.manualUserSettingsChange$.next(this.getUserSettings());
  }
}
