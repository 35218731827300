import { differenceInSeconds, isToday } from 'date-fns';
import { TimerAction, TimestampEntity } from '../timer/state/timer.interface';

export function calculateSessionTime(
  mode: TimerAction.Focus | TimerAction.Rest,
  entities: TimestampEntity[],
) {
  if (entities.length === 0) {
    return 0;
  }

  // Flag to know if the current session being calculated is the same as the {mode};
  // Used to add time in case we find a Resume action
  let isTargetSession = false;

  const oppositeMode = mode === TimerAction.Rest ? TimerAction.Focus : TimerAction.Rest;

  let totalTime = entities.reduce((totalSeconds, entity, index) => {
    let compareDate: Date | null = isToday(entity.timestamp) ? new Date() : null;

    if (entity.action === mode || (entity.action === TimerAction.Resume && isTargetSession)) {
      isTargetSession = true;
      if (index < entities.length - 1) {
        const nextEntry = entities[index + 1];
        if (
          nextEntry.action === TimerAction.Stop ||
          nextEntry.action === oppositeMode ||
          nextEntry.action === TimerAction.Pause
        ) {
          compareDate = new Date(entities[index + 1].timestamp);
        } else {
          // Cases where the user closes the tab while resting
          compareDate = null;
        }
      }

      if (compareDate) {
        totalSeconds += differenceInSeconds(compareDate, new Date(entity.timestamp));
      }
    } else if (entity.action === oppositeMode || entity.action === TimerAction.Stop) {
      isTargetSession = false;
    }
    return totalSeconds;
  }, 0);

  return totalTime;
}
