export enum TimerAction {
  Pause = 'pause',
  Resume = 'resume',
  Focus = 'focus',
  Rest = 'rest',
  Stop = 'stopped',
}

export interface TimestampEntity {
  id: number;
  action: TimerAction;
  timestamp: string;
}
